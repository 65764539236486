import React from 'react';
import GoogleMapReact from 'google-map-react';
  

const Map = ({ latitude, longitude }) => {
  const renderMarkers = (map, maps) => {
   let marker = new maps.Marker({
   position: { lat: 51.140991, lng: 0.271070 },
   map,
   title: 'Hello World!'
   });
   return marker;
  };
 
  return (
    <div data-aos="fade-down-left" data-aos-delay="50" data-aos-duration="2000" data-aos-easing="ease-in-out" id="google-map">
     <GoogleMapReact
       bootstrapURLKeys={{ key: 'AIzaSyCPp--EQfvEzBOxvHiPTL-PjiihmRP4ALY' }}
       defaultCenter={{ lat: 51.140991, lng:0.271070 }}
       defaultZoom={12}
       yesIWantToUseGoogleMapApiInternals
       onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
     >
     </GoogleMapReact>
    </div>
  );
 };


export default Map;

import React from 'react';

export class Services extends React.Component {
    render() {
        return(
            <div className="services-container">
                <div id="blue-bg" data-aos="slide-right" data-aos-delay="50" data-aos-duration="2600" data-aos-easing="ease-out-back" ></div>
            <h1 id="services">Services</h1>
            <div data-aos="slide-left" data-aos-delay="50" data-aos-duration="2000" data-aos-easing="ease-out" className="domestic-container">
                <div className="service-images" id="domestic-image"></div>
                <div className="services-text">
                    <h3>Domestic Cleaning</h3>
                    <p>We know how hectic life can get. Whether you need assistance with making your home
                         sparkling on a daily basis, or your house needs a thorough spring cleaning,
                          - and everything in between - we are here to help.</p>
                </div>
            </div>
            <div data-aos="slide-right" data-aos-delay="50" data-aos-duration="2000" data-aos-easing="ease-in-out" className="commercial-container">
                <div className="service-images" id="commercial-image"></div>
                <div className="services-text" id="commercial">
                    <h3>Commercial Cleaning</h3>
                    <p>We offer professional, comprehensive service for competitive prices to commercial properties,
                         offices and businesses of all shapes and sizes.</p>
                </div>
            </div>
            <div data-aos="slide-left" data-aos-delay="50" data-aos-duration="2000" data-aos-easing="ease-in-out" className="carpet-container">
                <div className="service-images" id="carpet-image"></div>
                <div className="services-text" id="carpet">
                    <h3>Carpet Cleaning</h3>
                    <p> Carpets can be a reservoir for allergy-causing substances that trigger respiratory diseases 
                        such asthma, therefore, it is important to keep carpets in good condition.
                         Our friendly team will ensure to keep your carpets dirt and bacteria free.</p>
                </div>
            </div>
            </div>
        );
    }
}

export default Services;
import React from 'react';



export class About extends React.Component {
    render() {
        return(
            <div className="about-container" id="about">
                <h1 id="about-header">About</h1>
                <div data-aos="zoom-in-up" data-aos-delay="50" data-aos-duration="2000" data-aos-easing="ease-in" id="about-wall"></div>
                <div data-aos="slide-right" data-aos-delay="350" data-aos-duration="2000" data-aos-easing="ease-in-out"id="sprayer"></div>
                <p data-aos="fade-up-left" data-aos-delay="500" data-aos-duration="2000" data-aos-easing="ease-in-out" id="about-text">Mr and Mrs Cleaning is a small family run business providing high quality cleaning services for both
                     domestic and commercial clientele since 2015.<br></br> Our success is based on our dedication to our work and
                      our clients and we aim to keep maintaining an excellent reputation for supplying the highest quality
                       of service at competitive prices.<br></br> Our mission is to serve our local community at the highest level,
                        while accommodating all our clients' specific needs.</p>

            </div>
        );
    }
}
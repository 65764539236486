import React from 'react';
import Map from './Map';
import ContactUs from './ContactUs';
import Details from './Details';



export class Contact extends React.Component {
   
    render() {
        return(
            <div id="contact">
                <h1>Contact</h1>
                < ContactUs />
                < Map />
                < Details />
                <footer>
                    <p>&copy; 2020 Mr and Mrs Cleaning, All rights reserved</p>
                </footer>
            </div>
        );
    }

  
}

export default Contact;
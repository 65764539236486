import React from 'react';
import emailjs from 'emailjs-com';

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
}


export default class ContactUs extends React.Component { 

    state = {
        errors: {
            name: '',
            email: '',
            message: '',
          },
         messageSent: null,
         sendButton: "Send"
      }

      constructor(props) {
          super(props);
          this.handleChange = this.handleChange.bind(this);
          this.handleSubmit = this.handleSubmit.bind(this);
         
      }


      handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
      
        switch (name) {
          case 'user_name': 
            errors.name = 
              value.length < 5
                ? 'Name must be at least 5 characters long!'
                : '';
            break;
          case 'user_email': 
            errors.email = 
              validEmailRegex.test(value)
                ? ''
                : 'Email is not valid!';
            break;
          case 'message': 
            errors.message = 
              value.length < 5
                ? 'Message must be longer than 5 characters long!'
                : '';
            break;
          default:
            break;
        }

        this.setState({errors, [name]: value}, ()=> {
            console.log(errors)
        })
    }  

    handleSubmit(e){

        e.preventDefault();

        if(validateForm(this.state.errors)) {
            console.info('Valid Form')

            this.setState({ sendButton: "Sending..."})

    
        emailjs.sendForm('service_oxcdf34', 'template_vdv2vtk', e.target, 'user_MxbfI53F3VRaDrVGKK0I1')
          .then((result) => {
              console.log(result.text);
              this.setState({
                  messageSent: "Thank you for your message. We will get back to you shortly.",
                  sendButton: "Send"

              })


          }, (error) => {
              console.log(error.text);
              this.setState({
                messageSent: "Something went wrong. Please try again later.",
                sendButton: "Send"
              })
          });


          e.target.reset();
        

        }else{
            console.error('Invalid Form');
          }

      }



      render() {
        const {errors} = this.state;
        return(
            <div data-aos="fade-up-right" data-aos-delay="50" data-aos-duration="2000" data-aos-easing="ease-out-back" id="contact-form">
                <h2>Contact Us</h2>
                <form className="contact-form" onSubmit={this.handleSubmit} noValidate>
                    <input type="hidden" name="contact_number" />
                    <input placeholder="Name" type="text" name="user_name" 
                     onChange={this.handleChange} noValidate /> 
                    <input placeholder="Email" type="email" name="user_email"
                      onChange={this.handleChange} noValidate/>
                    <textarea name="message" placeholder="Type Your message here....." 
                    onChange={this.handleChange} noValidate/>
                    {errors.message.length > 0 && 
                         <span className='error'>{errors.message}</span>}
                    {errors.name.length > 0 && 
                         <span className='error'>{errors.name}</span>}    
                    {errors.email.length > 0 && 
                         <span className='error'>{errors.email}</span>}
                    {this.state.messageSent != null ? <span className="message-sent">{this.state.messageSent}</span> : ""}
                    <input type="submit" value={this.state.sendButton} />
                </form>
            </div>
        );
      }
    
}


import React from 'react';
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import phone from './../../assets/images/phone.png';
import email from './../../assets/images/email.png';
import facebook from './../../assets/images/facebook.png';
import insta from './../../assets/images/insta.png';
import linkedin from './../../assets/images/linkedin.png';
import AnchorLink from 'react-anchor-link-smooth-scroll'



export class Landing extends React.Component {
    render() {
        return(
            <div className="landing-container" id="home">
                <header>
                       <div data-aos="fade-down" data-aos-delay="50" data-aos-duration="2900" data-aos-easing="ease-out-quart" id="logo"></div>
                        <a href="#main-menu" className="menu-toggle"><FontAwesomeIcon icon={faBars} /></a>
                        <nav id="main-menu" data-aos="fade-down-left" data-aos-delay="50" data-aos-duration="2900" data-aos-easing="ease-in-out" className="main-menu">
                        <a href="#main-menu-toggle" className="menu-close">
                        <FontAwesomeIcon icon={faTimes}/></a>
                        <ul> 
                            <li><AnchorLink href="#home">Home</AnchorLink></li>
                            <li><AnchorLink href="#services">Services</AnchorLink></li>
                            <li><AnchorLink href="#about">About</AnchorLink></li>
                            <li><AnchorLink href="#testimonials">Testimonials</AnchorLink></li>
                            <li><AnchorLink href="#contact">Contact</AnchorLink></li>
                            </ul>
                            </nav>
                            <a href="#main-menu-toggle" className="backdrop" hidden></a>
                </header>
                
                 <div data-aos="slide-right" data-aos-delay="50" data-aos-duration="2600" data-aos-easing="ease-out-back" id="window-cleaner"></div>
                 <div data-aos="zoom-in-up" data-aos-delay="50" data-aos-duration="1200" data-aos-easing="ease-out-back" className="intro">
                        <p>We are a family run company providing professional and reliable cleaning services for
                        <b> Tunbridge Wells </b>
                         and its surrounding areas.</p>
                 </div>
                 <div data-aos="zoom-in-up" data-aos-delay="1200" data-aos-duration="1200" data-aos-easing="ease-out-back" className="contact-data">
                 <img src={phone} alt="phone icon" className="phone-icon" />
                 <p id="phone-no">07538 760864</p>
                 <img src={email} alt="email icon" className="phone-icon" />
                 <p>contact@<br></br>mrandmrs-cleaning.co.uk</p>
                 </div>
                 <div data-aos="zoom-in-up" data-aos-delay="2000" data-aos-duration="1200" data-aos-easing="ease-out-back" className="social">
                     <a href="https://www.facebook.com/MrandMrsCleaningTunbridgeWells" target="_blank"><img src={facebook} alt="Facebook Link" /></a>
                     <a href="#"><img src={linkedin} alt="Facebook Link" /></a>
                     <a href="#"><img src={insta} alt="Facebook Link" /></a>
                 </div>
            </div>
        );
    }
}

export default Landing;
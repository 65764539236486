import React from 'react';
import './App.scss';
import { Landing } from './components/landing/Landing';
import { Services } from './components/services/Services';
import {About} from './components/about/About';
import { Testimonials } from './components/testimonials/Testimonials';
import { Contact } from './components/contact/Contact';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {

  AOS.init({
    once: true,
  });

  return (
    <div className="App">
     < Landing />
     < Services />
     < About />
     < Testimonials />
     < Contact />
    </div>
  );
}

export default App;

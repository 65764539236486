import React from 'react';

export class Testimonials extends React.Component {
    render() {
        return(
            <div id="testimonials">
                <div data-aos="slide-left" data-aos-delay="50" data-aos-duration="2000" data-aos-easing="ease-out-back" className="blue-banner"></div>
                <h1 id="testimonials-header">Testimonials</h1>
                <div data-aos="slide-left" data-aos-delay="50" data-aos-duration="2000" data-aos-easing="ease-in-out" className="testimonial-container" id="test01">
                    <p>"I would recommend Marcin. He is very professional, punctual and reliable. It is always very evident when he
                        has cleaned, the place is sparkling. I am very pleased."
                    </p>
                    <h3>Lola</h3>
                </div>
                <div data-aos="slide-right" data-aos-delay="500" data-aos-duration="2000" data-aos-easing="ease-in-out"className="testimonial-container" id="test02">
                    <p>"We have been using Mr and Mrs Cleaning for over 5 years now, which for a cleaning company speaks for itself.
                        Marcin and Ania offer just the right balance of professionalism and friendliness. They are a hardworking 
                        partnership where nothing is too much trouble. If you are looking for a reliable, friendly, trustworthy & 
                        professional cleaning company, that really cares about what they do, look no further than Mr and Mrs Cleaning!"
                    </p>
                    <h3>Laura</h3>
                </div>
                <div data-aos="slide-left" data-aos-delay="1000" data-aos-duration="2000" data-aos-easing="ease-in-out" className="testimonial-container" id="test03">
                    <p>
                        "Mr and Mrs Cleaning offers an extremely efficient, reliable and meticulous cleaning service with consistently 
                        high standards. They have a friendly, personal but professional approach to their clients, which makes them a 
                        pleasure to have around. They are always prepaired to go the extra mile for their clients and I am very happy 
                        to recommend them."
                    </p>
                    <h3>Sally Ann</h3>
                </div>
                <div data-aos="slide-right" data-aos-delay="50" data-aos-duration="2000" data-aos-easing="ease-in-out" className="testimonial-container" id="test04">
                    <p>
                        "I have used Mr and Mrs Cleaning services regularly each week for two years and our house would not be the
                        same without them! We have been very pleased with their house cleaning and carpet cleaning. We highly recommend 
                        their services. Martin is friendly, punctual and extremely professional. He works hard and the quality of his 
                        work is excellent. His knowledge of cleaning products is excellent and his attention to details is excellent. 
                        He is reliable and trustworthy and we are very happy for him to come to our home."
                    </p>
                    <h3>Jennie</h3>
                </div>
        
            </div>
        );
    }
}

export default Testimonials;
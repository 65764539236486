import React from 'react';
import phone from './../../assets/images/phone.png';
import email from './../../assets/images/email.png';
import facebook from './../../assets/images/facebook.png';
import insta from './../../assets/images/insta.png';
import linkedin from './../../assets/images/linkedin.png';



export class Details extends React.Component {

    render() {
        return(
            <div data-aos="slide-left" data-aos-delay="50" data-aos-duration="2000" data-aos-easing="ease-out" id="details-container">
                < div className="contact-data">
                    <img src={phone} alt="phone icon" className="phone-icon" />
                    <p id="phone-no">07538 760864</p>
                    <img src={email} alt="email icon" className="phone-icon" />
                    <p>contact@<br></br>mrandmrs-cleaning.co.uk</p>
                </div>
                <div id="address">
                    <h2>11 Bridge Court</h2>
                    <h2>Medway Drive</h2>
                    <h2>Tunbridge Wells</h2>
                    <h2>TN1 2FE</h2>
                </div>
                <div className="social">
                    <a href="#"><img src={facebook} alt="Facebook Link" /></a>
                    <a href="#"><img src={linkedin} alt="Facebook Link" /></a>
                    <a href="#"><img src={insta} alt="Facebook Link" /></a>
                </div>
            </div>
        );
    }
}

export default Details;